<template>
  <div class="search-container row" :class="{active}">
    <div class="search-icon" @click="showSearch">
      <SearchIcon style="cursor: pointer;" width="28px" height="28px"/>
    </div>
    <div id="search-mask" @click="closeSearch" class="mask" :class="{active}">

    </div>
    <div class="search row" :class="{active}">
      <div class="input">
        <form @submit.stop.prevent="submit">
          <HiddenInput @mounted="input=$event" ref="input" v-model="word" name="search"
                       id="searching" autofocus
                       :placeholder="$t('searching.placeholder')"/>
        </form>
      </div>
      <CrossIcon class="close" @click="closeSearch"/>
      <div class="microphone-block" @click="startRecognizer">
        <img class="microphone" v-if="start_voice" title="Microphone" src="/gif/voice.gif"
             alt="Microphone">
        <img class="microphone" v-else title="Microphone" src="/svg/icons/microphone.svg"
             alt="Microphone">
      </div>
    </div>
    <div id="search-panel" :class="{active}">
      <div class="search-results row full-width" :class="{active:results?.categories?.length || results?.collections?.length || results?.products?.length}">
        <div class="column" v-if="results?.categories?.length || results?.collections?.length">
          <div class="list" v-if="results?.categories?.length">
            <div class="title">{{ $t('category.categories') }}</div>

            <div class="results categories">
              <nuxt-link @click="linkClick" :to="'/'+category.fields.slug" class="category-item"
                         v-for="category in results.categories">
                <Text><span>{{ category.fields.name }}</span> <span>({{ category.fields.products_count }})</span></Text>
              </nuxt-link>
            </div>
          </div>
          <div class="list" v-if="results?.collections?.length">
            <div class="title">{{ $t('collection.alternative_collection') }}</div>

            <div class="results collections">
              <nuxt-link @click="linkClick" :to="'/'+collection.fields.slug" class="collection-item"
                         v-for="collection in results.collections">
                <div class="image-side">
                  <img :src="'https://hillceramic.se'+collection.fields.image">
                </div>
                <div class="info-side">
                  <div class="top">
                    <div class="name">{{ collection.fields.name }}</div>
                  </div>
                  <div class="bottom row">
                    <div class="color" v-for="color in collection.fields.colors">
                      <img
                          :src="'https://hillceramic.se'+color.path">
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="column" v-if="results?.products?.length">
          <div class="list">
            <div class="title">{{ $t('product.products') }}</div>

            <div class="results products">
              <nuxt-link @click="linkClick" :to="'/shop/'+product.fields.slug" class="item"
                         v-for="product in results.products">
                <div class="image-side no-flex">
                  <img
                      :src="product.fields.image"
                      alt="">
                </div>
                <div class="info-side">
                  <div class="name" v-html="product.fields.name"></div>
                  <div class="bottom row full-width">
                    <div class="row" style="gap: 5px">
                      <ProductPrice :price="product.fields.price" :digits="0" class="price"/>
                      <ProductPrice style="font-weight: 600" :price="product.fields.old_price" :digits="0" type="old"
                                    class="price"/>
                    </div>
                    <div class="sku">
                      SKU: {{ product.fields.sku }}
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderIcon from "~/components/molecules/HeaderIcon.vue";
import Text from "~/components/atoms/Text.vue";
import ProductPrice from "~/components/molecules/ProductPrice.vue";
import {useRuntimeConfig} from 'nuxt/app';
import SearchIcon from "~/components/icons/SearchIcon.vue";
import HiddenInput from "~/components/atoms/HiddenInput.vue";
import {useRouter} from "vue-router";
import CrossIcon from "~/components/icons/CrossIcon.vue";
import {useCustomFetch} from '~/composables/customFetch.js';

export default {
  data() {
    return {
      router: useRouter(),
      active: false,
      word: '',
      start_voice: false,
      finish_voice: false,
      recognition: null,
      results: {
        categories: [],
        collections: [],
        products: []
      },
      input: null
    }
  },
  components: {
    CrossIcon,
    HiddenInput,
    SearchIcon,
    ProductPrice,
    Text,
    HeaderIcon
  },
  watch: {
    word() {
      this.search()
    }
  },
  mounted() {
    this.showSearch()
  },
  methods: {
    submit(e) {
      useCustomFetch('/search/direct', {
        lazy: false,
        query: {
          search: this.word
        }
      }).then(data => {
        if (data.data.value.url) {
          this.router.push(data.data.value.url)
          this.closeSearch()
        }
      })
    },
    search() {
      if (!this.word) return
      const runtimeConfigs = useRuntimeConfig();

      $fetch(`${runtimeConfigs.public.API_URL}/search`, {
        lazy: false,
        query: {
          api_token: runtimeConfigs.public.apiToken,
          word: this.word
        }
      }).then(data => {
        this.results.categories = data?.categories ?? []
        this.results.collections = data?.collections ?? []
        this.results.products = data?.products ?? []
      })
    },
    startRecognizer() {
      if (this.start_voice) {
        this.start_voice = false;
        this.recognition.abort();
        return;
      }

      if ('webkitSpeechRecognition' in window) {
        this.start_voice = true;
        var permission = false;

        if (!this.recognition) {
          this.recognition = new webkitSpeechRecognition();
        }

        //recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onstart = function () {
          permission = true;
        }

        this.recognition.onend = function () {
          permission = false;
        }

        this.recognition.lang = 'sv_SE';
        this.recognition.onresult = (event) => {
          var result = event.results[event.resultIndex];
          this.word = result[0].transcript;

          if (result.isFinal) {
            this.start_voice = false;
            this.finish_voice = true;
          }
        };

        this.recognition.start();
      }

    },
    showSearch() {
      this.active = true

      // setTimeout(() => {
      //   document.querySelector('#searching')?.focus()
      // }, 200)
    },
    closeSearch() {
      this.active = false
      this.word = ''
    },
    linkClick() {
      this.closeSearch()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.search-container {
  height: 50px;

  #search-panel {
    display: none;
    z-index: 9999;
    top: 50px;
    position: absolute;
    width: 700px;
    background: $light-grey;
    left: -10px;
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    &.active {
      display: block;
    }

    .search-results {
      align-items: flex-start;

      @include smd {
        flex-direction: column;
        background: $white;
        left: 0;
        position: fixed;
        top: 75px;
        overflow: auto;
      }

      &.active {
        @include smd {
          max-height: 100vh;
          height: 100vh;
          padding-bottom: 150px;
        }
      }

      .column {
        justify-content: flex-start;
        width: 100%;
        height: 529px;
        border-right: 1px solid white;

        @include smd {
          height: auto;
        }

        &:last-child {
          border: 0;
        }


        .list {
          background: white;

          .title {
            text-transform: uppercase;
            background: $black;
            color: $white;
            font-size: 14px;
            min-height: 30px;
            max-height: 30px;
            display: flex;
            justify-content: center;
            padding-left: 23px;
            font-weight: 600;

            @include smd {
              max-height: 30px;
            }

          }

          .results {
            height: 499px;
            max-height: 499px;
            overflow-y: auto;
            background: white;

            @include smd {
              max-height: 232px;
              overflow: auto;
            }

            &.categories, &.collections {
              height: 235px;
              max-height: 235px;
              min-height: 235px;

              @include smd {
                max-height: 232px;
                overflow: auto;
              }
            }

            &.collections {
              gap: 20px;
              padding: 20px 0;

              .collection-item {
                background: #fff;
                box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                padding: 0;
                margin: 0 19px;
                align-items: inherit;
                border-radius: 3px;
                display: flex;
                flex-direction: row;
                overflow: hidden;
                min-height: 86px;

                &:hover {
                  background: $border-grey;
                }

                .image-side {
                  width: 86px;
                  height: 86px;
                  max-width: 86px;
                  max-height: 86px;

                  img {
                    width: 100%;
                  }
                }

                .info-side {
                  padding: 10px 10px 10px 12px;

                  .top {
                    .name {
                      font-size: 13px;
                      font-weight: 600;
                      color: $black;
                      text-transform: uppercase;
                    }
                  }

                  .bottom {
                    gap: 6px;

                    .color {
                      width: 31px;
                      height: 31px;
                      max-width: 31px;
                      max-height: 31px;

                      img {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }

            .category-item {
              padding: 10px 19px;

              p {
                display: flex;
                gap: 10px;
              }
            }

            &.products {
              a {
                display: flex;
                flex-direction: row;
                padding: 12px 19px;
                gap: 20px;
                font-size: 12px;

                &:hover {
                  background: $white;
                }

                img {
                  width: 76px;
                  height: 76px;
                  max-width: 76px;
                  max-height: 76px;
                }

                .name {
                  flex-direction: row;
                  flex-wrap: wrap;
                  column-gap: 10px;
                  display: block;
                }

                .sku {
                  font-size: 10px;
                  text-align: right;
                  color: $light-black;
                }
              }
            }
          }
        }
      }
    }
  }

  &:before {
    content: "";
    background: transparent;
    left: -10px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    z-index: 9999;
  }

  .close {
    width: 12px;
    position: absolute;
    right: 50px;
    display: block;
    cursor: pointer;
  }

  .microphone-block {
    width: 28px;
    height: 28px;
    max-width: 28px;
    max-height: 28px;
    align-items: center;
    justify-content: center;

    .microphone {
      width: 20px;
    }
  }

  .mask.active {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
  }

  .search {
    display: none;
    width: 425px;
    padding-right: 11px;
    position: relative;
    height: 50px;
    align-items: center;

    @include smd {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: unset;
      height: 52px;
    }

    &.active {
      display: flex;
      z-index: 9999;
      background: $white;

      @include smd {
        top: 25px;
      }
    }

    .input {
      margin-left: 15px;
      margin-right: 12px;

      &:after {
        display: block;
        content: "";
        margin-top: 0;
        height: 6px;
        border-bottom: 1px solid $dark-black;
        transform: scaleX(1);
        transition: transform .5s ease-in-out;
      }
    }
  }

  .search-icon {
    z-index: 9999;
    background: transparent;
    height: 100%;
    justify-content: center;

    svg {
      height: 24px;
    }
  }

  &.active {
    &:before {
      background: $white;
    }

    .search-icon {
      background: $white;
    }
  }
}

.price {
  font-size: 13px !important;
}
</style>
